<template>
  <!-- Notfound Area Start -->
  <section class="ekifa-notfound-area section_80">
    <b-container>
      <b-row>
        <b-col md="12">
          <div class="notfound-box">
            <h1>404</h1>
            <h4>Oopps... Page Not Found!</h4>
            <p>The URL may be misplaced or the path you are looking is no longer available.</p>
            <b-link to="/" class="ekifa-btn">Back to home</b-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Notfound Area End -->
</template>

<script>
export default {
  name: "NotFoundArea"
}
</script>

<style scoped>

</style>